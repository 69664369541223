import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';

import { environment } from '../../../environments/environment';

const rollbarConfig = {
  accessToken: environment.rollbarAccessToken,
  environment: environment.environment,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.production,
  payload: {
    client: {
      code_version: environment.commit_sha,
      source_map_enabled: true,
      guess_uncaught_frames: true,
    },
    server: {
      host: environment.shopDomain,
    },
  },
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  rollbar: any;
  constructor(private injector: Injector) {
    this.rollbar = injector.get(RollbarService);
  }

  handleError(err: any): void {
    console.error(err);
    this.rollbar.error(err.originalError || err);
  }
}
