import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { GomusCommonModule } from '@gomus/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { GomusHttpInterceptor } from './shared/components/http/http.interceptor';
import { BarcoderService } from './shared/services/barcoder.service';
import { ConfigResolver } from './shared/services/config-resolver.service';
import { ConfigService } from './shared/services/config.service';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './shared/services/error-handler.service';
import { OrderService } from './shared/services/order.service';
import { commonModuleOptions } from './common-options';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    GomusCommonModule.forRoot(commonModuleOptions()),
    AppRoutingModule,
    HttpClientModule,
    NoopAnimationsModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    ConfigResolver,
    ConfigService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GomusHttpInterceptor,
      multi: true,
    },
    OrderService,
    BarcoderService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
