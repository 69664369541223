import 'rxjs/add/operator/toPromise';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';

import { environment } from '../../../environments/environment';
import { Error } from '../components/error';
import { Config } from '../gomus/config';
import { Korona } from '../korona';
import { RollbarService } from './error-handler.service';

declare var korona_plugin_api: Korona;

@Injectable()
export class ConfigService {
  config: Config;
  rollbar: Rollbar;

  constructor(private http: HttpClient, injector: Injector) {
    this.rollbar = injector.get(RollbarService);
  }

  /**
   * Gets config from go~mus shop settings
   */
  getConfig() {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-Shop-Url': environment.shopDomain, // edge-kasse.gomus.de
      }),
    };
    return this.http
      .get(`https://${environment.domain}/api/v4/shop`, httpOptions)
      .toPromise()
      .catch(this.handleError);
  }

  private handleError = (error): Promise<Error> => {
    const posNumber = korona_plugin_api && korona_plugin_api.request && korona_plugin_api.request.pos.number;
    this.rollbar.warn({ ...error, posNumber });
    console.error('An error occurred', error);
    return Promise.reject(error);
  };

  get(key: any) {
    return this.config[key];
  }

  set(config: any, token: string) {
    this.config = new Config(config, token);
  }
}
