import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { RollbarService } from '../services/error-handler.service';

import { ConfigService } from './config.service';
import { Korona } from '../korona';

declare var korona_plugin_api: Korona;

@Injectable()
export class ConfigResolver implements Resolve<Object> {
  rollbar: any;
  constructor(private config: ConfigService, private injector: Injector) {
    this.rollbar = injector.get(RollbarService);
  }

  resolve(route: ActivatedRouteSnapshot) {
    const token = route.params['token'];
    return this.config
      .getConfig()
      .then(
        (resp: any) => {
          return new Promise((resolve) => {
            if (korona_plugin_api.request) {
              this.config.set(resp.shop.settings, token);
              resolve(true);
            } else {
              const ref = setTimeout(() => {
                this.config.set(resp.shop.settings, token);
                resolve(true);
              }, 3600);
              korona_plugin_api.ready(() => {
                this.config.set(resp.shop.settings, token);
                clearTimeout(ref);
                resolve(true);
              });
            }
          });
        },
        error => {
          this.config.set({}, token);
          this.rollbar.warn(error);
          return true;
        }
      )
      .catch(error => {
        this.initConfig({}, token);
        this.rollbar.warn(error);
        return true;
      });
  }

  private initConfig(settings, token) {
    this.config.set(settings, token);
  }
}
