import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ConfigResolver } from './shared/services/config-resolver.service';

const routes: Routes = [
  {
    path: ':token',
    resolve: {
      config: ConfigResolver,
    },
    children: [
      { path: 'events', loadChildren: './events/events.module#EventsModule' },
      { path: 'groups', loadChildren: './groups/groups.module#GroupsModule' },
      { path: 'tickets', loadChildren: './tickets/tickets.module#TicketsModule' },
      { path: 'orders', loadChildren: './orders/orders.module#OrdersModule' },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
