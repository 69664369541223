import { Injectable } from '@angular/core';

@Injectable()
export class BarcoderService {
  format = { validChars: /^[\d_.-]*$/, validLength: 13 };
  options = { enableZeroPadding: false };

  /**
   * Barcoder class
   *
   * @api public
   */
  constructor() {}

  /**
   * Validates the checksum (Modulo 10)
   * GTIN implementation factor 3
   *
   * @param  {String} value The barcode to validate
   * @return {Boolean}
   * @api private
   */
  private validateGtin = value => {
    const barcode = value.substring(0, value.length - 1);
    const checksum = parseInt(value.substring(value.length - 1), 10);
    let calcSum = 0;
    let calcChecksum = 0;

    barcode.split('').map(function(number, index) {
      number = parseInt(number, 10);
      if (value.length % 2 === 0) {
        index += 1;
      }
      if (index % 2 === 0) {
        calcSum += number;
      } else {
        calcSum += number * 3;
      }
    });

    calcSum %= 10;
    calcChecksum = calcSum === 0 ? 0 : 10 - calcSum;

    if (calcChecksum !== checksum) {
      return false;
    }

    return true;
  };

  /**
   * Validates a barcode
   *
   * @param  {string}  barcode   EAN/GTIN barcode
   * @return {Boolean}
   * @api public
   */

  validate(barcode): boolean {
    const validChars = this.format.validChars;
    const validLength = this.format.validLength;
    const enableZeroPadding = this.options.enableZeroPadding;

    // support new type of barcodes
    const regex = /2\d\d[a-zA-Z0-9-_]{12,}/;
    if (regex.exec(barcode)) {
      return true;
    }

    if (validChars.exec(barcode) === null) {
      return false;
    }

    if (enableZeroPadding && barcode.length < validLength) {
      let missingZeros = validLength - barcode.length;
      while (missingZeros--) {
        barcode = '0' + barcode;
      }
    } else if (!enableZeroPadding && barcode.length !== validLength) {
      return false;
    } else if (barcode.length > validLength) {
      return false;
    }

    return this.validateGtin(barcode);
  }
}
