import { Observable } from 'rxjs/Observable';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
var DEFAULT_DELAY_MILLIS = 1000;
var DEFAULT_MAX_RETRIES = 5;
var DEFAULT_BACKOFF_MILLIS = 1000;
/**
 * Retry's the Observable with optional backoff period.
 *
 * @param delayMillis Milliseconds the subsequent retries should be delayed (default = 1000).
 * @param maxRetry Max number of retries (default = 5).
 * @param backoffMillis Additional backoff time (default = 1000). Delay for each subsequent request is calculated as follows:
 *    <delayMillis> + <currentRetryStartingFrom0> * <backoffMillis>
 *    eg. for Default: 1000 ... 2000 ... 3000 ... 4000 ... 5000
 * @param enabled Can be used to dynamically disable the retry depending on the error. true (default) -> retry enabled
 * @param logger Can be used to perform custom error logging when a retry happens.
 */
export function retry(_a) {
    var _b = _a.maxRetries, maxRetries = _b === void 0 ? DEFAULT_MAX_RETRIES : _b, _c = _a.delayMillis, delayMillis = _c === void 0 ? DEFAULT_DELAY_MILLIS : _c, _d = _a.backoffMillis, backoffMillis = _d === void 0 ? DEFAULT_BACKOFF_MILLIS : _d, _e = _a.enabled, enabled = _e === void 0 ? function () { return true; } : _e, _f = _a.logger, logger = _f === void 0 ? function () {
    } : _f;
    var currentRetry = 0;
    return function (src) {
        return src.pipe(retryWhen(function (errors) { return errors.pipe(mergeMap(function (error) {
            if (enabled(error) && currentRetry < maxRetries) {
                var currentDelayMillis = delayMillis + currentRetry * backoffMillis;
                currentRetry++;
                logger(error, currentRetry, currentDelayMillis);
                return of(error).pipe(delay(currentDelayMillis));
            }
            return Observable.throw(error);
        })); }));
    };
}
