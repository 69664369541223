var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ConfigService } from '../../services/config.service';
import { retry } from '../../services/serviceutils';
import * as Rollbar from 'rollbar';
/**
 * Adds the authentication header to requests that have the `withCredentials`
 * options set.
 *
 * @export
 * @class GomusHttpInterceptor
 * @implements {HttpInterceptor}
 */
var GomusHttpInterceptor = /** @class */ (function () {
    function GomusHttpInterceptor(config, rollbar) {
        this.config = config;
        this.rollbar = rollbar;
    }
    GomusHttpInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        // Clone the request to add the new header.
        if (!req.withCredentials) {
            return next.handle(req);
        }
        var authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.config.get('apiToken')) });
        // Pass on the cloned request instead of the original request.
        // And add retrying if http status code is 0. This might fix https://giantmonkey.atlassian.net/browse/GOM-3295
        return next.handle(authReq).pipe(retry({
            backoffMillis: 0,
            delayMillis: 500,
            enabled: function (error) { return error && error.status === 0; },
            logger: function (error, currentRetry, currentDelayMillis) {
                _this.retryLogger(error, currentRetry);
            }
        }));
    };
    GomusHttpInterceptor.prototype.retryLogger = function (error, currentRetry) {
        this.rollbar.info(__assign({}, error, { retry: currentRetry }), 'Retrying API call because an error happened.');
    };
    return GomusHttpInterceptor;
}());
export { GomusHttpInterceptor };
