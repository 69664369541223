import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ConfigService } from '../../services/config.service';
import { retry } from '../../services/serviceutils';
import { RollbarService } from '../../services/error-handler.service';
import * as Rollbar from 'rollbar';

/**
 * Adds the authentication header to requests that have the `withCredentials`
 * options set.
 *
 * @export
 * @class GomusHttpInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class GomusHttpInterceptor implements HttpInterceptor {
  constructor(private config: ConfigService, @Inject(RollbarService) private rollbar: Rollbar) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    if (!req.withCredentials) {
      return next.handle(req);
    }

    const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.config.get('apiToken')) });
    // Pass on the cloned request instead of the original request.
    // And add retrying if http status code is 0. This might fix https://giantmonkey.atlassian.net/browse/GOM-3295

    return next.handle(authReq).pipe(
      retry({
        backoffMillis: 0,
        delayMillis: 500,
        enabled: error => error && error.status === 0,
        logger: (error, currentRetry, currentDelayMillis) => {
          this.retryLogger(error, currentRetry);
        }
      })
    );
  }

  private retryLogger(error, currentRetry) {
    this.rollbar.info({
      ...error,
      retry: currentRetry,
    }, 'Retrying API call because an error happened.');
  }
}
