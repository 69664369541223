var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import 'rxjs/add/operator/toPromise';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Config } from '../gomus/config';
import { RollbarService } from './error-handler.service';
var ConfigService = /** @class */ (function () {
    function ConfigService(http, injector) {
        var _this = this;
        this.http = http;
        this.handleError = function (error) {
            var posNumber = korona_plugin_api && korona_plugin_api.request && korona_plugin_api.request.pos.number;
            _this.rollbar.warn(__assign({}, error, { posNumber: posNumber }));
            console.error('An error occurred', error);
            return Promise.reject(error);
        };
        this.rollbar = injector.get(RollbarService);
    }
    /**
     * Gets config from go~mus shop settings
     */
    ConfigService.prototype.getConfig = function () {
        var httpOptions = {
            headers: new HttpHeaders({
                'X-Shop-Url': environment.shopDomain,
            }),
        };
        return this.http
            .get("https://" + environment.domain + "/api/v4/shop", httpOptions)
            .toPromise()
            .catch(this.handleError);
    };
    ConfigService.prototype.get = function (key) {
        return this.config[key];
    };
    ConfigService.prototype.set = function (config, token) {
        this.config = new Config(config, token);
    };
    return ConfigService;
}());
export { ConfigService };
