import { Observable } from 'rxjs/Observable';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';

const DEFAULT_DELAY_MILLIS = 1000;
const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_BACKOFF_MILLIS = 1000;

export interface RetryOptions {
  readonly maxRetries?: number;
  readonly delayMillis?: number;
  readonly backoffMillis?: number;

  enabled?(error: any): boolean;
  logger?(error: any, currentRetry: number, currentDelayMillis: number): void;
}

/**
 * Retry's the Observable with optional backoff period.
 *
 * @param delayMillis Milliseconds the subsequent retries should be delayed (default = 1000).
 * @param maxRetry Max number of retries (default = 5).
 * @param backoffMillis Additional backoff time (default = 1000). Delay for each subsequent request is calculated as follows:
 *    <delayMillis> + <currentRetryStartingFrom0> * <backoffMillis>
 *    eg. for Default: 1000 ... 2000 ... 3000 ... 4000 ... 5000
 * @param enabled Can be used to dynamically disable the retry depending on the error. true (default) -> retry enabled
 * @param logger Can be used to perform custom error logging when a retry happens.
 */
export function retry({
                        maxRetries = DEFAULT_MAX_RETRIES,
                        delayMillis = DEFAULT_DELAY_MILLIS,
                        backoffMillis = DEFAULT_BACKOFF_MILLIS,
                        enabled = () => true,
                        logger = () => {
                        }
                      }: RetryOptions) {
  let currentRetry = 0;

  return (src: Observable<any>) =>
    src.pipe(
      retryWhen((errors: Observable<any>) => errors.pipe(
        mergeMap(error => {
            if (enabled(error) && currentRetry < maxRetries) {
              const currentDelayMillis = delayMillis + currentRetry * backoffMillis;

              currentRetry++;

              logger(error, currentRetry, currentDelayMillis);

              return of(error).pipe(delay(currentDelayMillis));
            }

            return Observable.throw(error);
          }
        ))
      )
    );
}
